import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Polaroids from './pages/polaroids';
import Film from './pages/film';
import Note from './pages/note';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Navigate replace to="/polaroids" />} />
          <Route path="/polaroids" element={<Polaroids />} />
          <Route path="/film" element={<Film />} />
          <Route path="/note" element={<Note />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
