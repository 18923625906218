// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

function Header() {

    return (
        <header>
            <nav className="header-nav">
                <h1 className="logo"><Link to="/">Ali's Archive</Link></h1>
                <ul className="nav-menu">
                    <li className="nav-item"><Link to="/">Polaroids</Link></li>
                    <li className="nav-item"><Link to="/film">Film</Link></li>
                    <li className="nav-item"><Link to="/note">A Note</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
