import React, { useState, useEffect } from 'react';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

const GalleryItem = ({ src, alt }) => (
    <a href={src} className="glightbox gallery-item">
      <img className="gallery-img" src={src} alt={alt} loading="lazy" />
    </a>
  );
  

function Gallery({ images }) {
  const [visibleImages, setVisibleImages] = useState([]);
  const imagesPerPage = 12;
  let arrayForHoldingImages = [];

  useEffect(() => {
    loadMoreImages();
  }, [images]);

  useEffect(() => {
    const lightbox = GLightbox({
      selector: 'a.glightbox', // Adjust if necessary
    });

    return () => lightbox.destroy();
  }, [visibleImages]);

  const loadMoreImages = () => {
    const lastIndex = visibleImages.length;
    const nextImages = images.slice(lastIndex, lastIndex + imagesPerPage);
    arrayForHoldingImages = [...visibleImages, ...nextImages];
    setVisibleImages(arrayForHoldingImages);
  };

  // Simulating infinite scroll
  window.onscroll = function() {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight-500) {
      loadMoreImages();
    }
  };

  return (
    <main className="gallery-page">
      <section className="gallery-auto-fit">
        {visibleImages.map((image, index) => (
          <GalleryItem key={index} src={image.src} alt={`Image ${index}`} />
        ))}
      </section>
    </main>
  );
}

export default Gallery;
