import Gallery from '../components/Gallery';


const filmImages = [];
const totalfilmImages = 18;
for(let i = 1; i <= totalfilmImages; i++) {
    filmImages.push({
    src: `/film/img${i}.jpeg`,
    alt: `Description ${i}`
  });
}
function Film() {
  return (
    <div>
      <Gallery images={filmImages} />
    </div>
  );
}

export default Film;


