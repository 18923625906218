import React from 'react';
import '../App.css';

function Note() {
    return (
        <main className="gallery-page">
            <section className="gallery-auto-fit">
                <p style={{ textAlign: 'center' }}>
                    <img alt="A note" src="/note1.jpeg" />
                </p>
            </section>
        </main>
    );
}

export default Note;
