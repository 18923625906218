import React, { useEffect, useState } from 'react';
import Gallery from '../components/Gallery';

const filmImages = [];
const totalfilmImages = 75;
for(let i = 1; i <= totalfilmImages; i++) {
    filmImages.push({
    src: `/polaroids/img${i}.jpeg`,
    alt: `Description ${i}`
  });
}
function Polaroids() {
  return (
    <div>
      <Gallery images={filmImages} />
    </div>
  );
}

export default Polaroids;


